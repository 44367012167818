"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const mobx_1 = require("mobx");
const risesharp_common_1 = require("risesharp-common");
const base_1 = require("./base");
class Signin extends base_1.Base {
  constructor() {
    var _this;
    super({});
    _this = this;
    this.resetState = mobx_1.action(() => {
      this.resetObservable();
    });
    this.resetValidationState = mobx_1.action(function (identifier) {
      let subIdentifier = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
      _this.modifyValidationState(_this.getValidationDefault(identifier), identifier, subIdentifier);
    });
    this.resetApiState = mobx_1.action(function (identifier) {
      let subIdentifier = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
      _this.modifyApiState(risesharp_common_1.Schema.getDefaultApiMessages(risesharp_common_1.Schema.IDENTIFIER_ONLYERROR), identifier, subIdentifier);
    });
    this.resetState();
  }
  resetObservable() {
    mobx_1.extendObservable(this, {
      state: Object.assign(this.state, {
        form: {
          [risesharp_common_1.Constants.IDENTIFIER_SIGNIN]: this.getFormDefault(risesharp_common_1.Constants.IDENTIFIER_SIGNIN)
        },
        validation: {
          [risesharp_common_1.Constants.IDENTIFIER_SIGNIN]: this.getValidationDefault(risesharp_common_1.Constants.IDENTIFIER_SIGNIN)
        },
        api: {
          [risesharp_common_1.Constants.IDENTIFIER_SIGNIN]: this.getApiDefault(risesharp_common_1.Constants.IDENTIFIER_SIGNIN)
        },
        spinner: {
          [risesharp_common_1.Constants.IDENTIFIER_SIGNIN]: this.getSpinnerDefault()
        }
      })
    });
  }
  getFormDefault(identifier) {
    if (identifier === risesharp_common_1.Constants.IDENTIFIER_SIGNIN) {
      return {
        step_1: true,
        step_2: false,
        email_or_mobile: '',
        request_id: '',
        verify_code: '',
        next_attempt_at: 0,
        otp_countdown_timer: 0,
        otp_countdown_on: false,
        otp_countdown_intvid: null
      };
    }
    return {};
  }
  getValidationDefault(identifier) {
    if (identifier === risesharp_common_1.Constants.IDENTIFIER_SIGNIN) {
      return {
        email_or_mobile: {
          isError: false,
          errorMessage: ''
        },
        verify_code: {
          isError: false,
          errorMessage: ''
        }
      };
    }
    return {};
  }
  getApiDefault(identifier) {
    if (identifier === risesharp_common_1.Constants.IDENTIFIER_SIGNIN) {
      return Object.assign({}, risesharp_common_1.Schema.getDefaultApiMessages(), {
        otpCodeDetails: risesharp_common_1.Schema.getOtpCodeDetails(),
        userDetails: risesharp_common_1.Schema.getAuthenticatedUserDetails()
      });
    }
    return {};
  }
  getSpinnerDefault() {
    return false;
  }
}
exports.default = Signin;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const mobx_1 = require("mobx");
const risesharp_common_1 = require("risesharp-common");
const base_1 = require("./base");
class Signup extends base_1.Base {
  constructor() {
    var _this;
    super({});
    _this = this;
    this.resetState = mobx_1.action(() => {
      this.resetObservable();
    });
    this.resetValidationState = mobx_1.action(function (identifier) {
      let subIdentifier = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
      _this.modifyValidationState(_this.getValidationDefault(identifier), identifier, subIdentifier);
    });
    this.resetApiState = mobx_1.action(function (identifier) {
      let subIdentifier = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
      _this.modifyApiState(risesharp_common_1.Schema.getDefaultApiMessages(risesharp_common_1.Schema.IDENTIFIER_ONLYERROR), identifier, subIdentifier);
    });
    this.resetState();
  }
  resetObservable() {
    mobx_1.extendObservable(this, {
      state: Object.assign(this.state, {
        form: {
          [risesharp_common_1.Constants.IDENTIFIER_SIGNUP]: this.getFormDefault(risesharp_common_1.Constants.IDENTIFIER_SIGNUP)
        },
        validation: {
          [risesharp_common_1.Constants.IDENTIFIER_SIGNUP]: this.getValidationDefault(risesharp_common_1.Constants.IDENTIFIER_SIGNUP)
        },
        api: {
          [risesharp_common_1.Constants.IDENTIFIER_SIGNUP]: this.getApiDefault(risesharp_common_1.Constants.IDENTIFIER_SIGNUP)
        },
        spinner: {
          [risesharp_common_1.Constants.IDENTIFIER_COMPONENTMOUNT]: this.getSpinnerDefault(),
          [risesharp_common_1.Constants.IDENTIFIER_SIGNUP]: this.getSpinnerDefault()
        },
        displayForm: {
          [risesharp_common_1.Constants.IDENTIFIER_SIGNUP]: this.getDisplayFormDefault()
        },
        coursesList: {
          [risesharp_common_1.Constants.IDENTIFIER_SIGNUP]: []
        },
        redirectUrl: {
          [risesharp_common_1.Constants.IDENTIFIER_SIGNUP]: ''
        }
      })
    });
  }
  getFormDefault(identifier) {
    if (identifier === risesharp_common_1.Constants.IDENTIFIER_SIGNUP) {
      return {
        first_name: '',
        last_name: '',
        email: '',
        mobile: '',
        user_type: '',
        college_code: '',
        college_identifier: '',
        college_enrollment_no: '',
        degree_program: '',
        degree_code: '',
        degree_duration: 0,
        degree_specialization: '',
        college_admission_year: '',
        college_graduation_year: ''
      };
    }
    return {};
  }
  getValidationDefault(identifier) {
    if (identifier === risesharp_common_1.Constants.IDENTIFIER_SIGNUP) {
      return {
        first_name: {
          isError: false,
          errorMessage: ''
        },
        email: {
          isError: false,
          errorMessage: ''
        },
        mobile: {
          isError: false,
          errorMessage: ''
        }
      };
    }
    return {};
  }
  getApiDefault(identifier) {
    if (identifier === risesharp_common_1.Constants.IDENTIFIER_SIGNUP) {
      return Object.assign({}, risesharp_common_1.Schema.getDefaultApiMessages(risesharp_common_1.Schema.IDENTIFIER_ONLYERROR), {
        signupDetails: risesharp_common_1.Schema.getResendOtpTokenDetails()
      });
    }
    return {};
  }
  getSpinnerDefault() {
    return false;
  }
  getDisplayFormDefault() {
    return true;
  }
}
exports.default = Signup;